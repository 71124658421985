
.modal-backdrop {
  z-index: 100000 !important;
}

.modal {
  z-index: 100001 !important;
}

.access-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.access-container input{
  width: 150px;
  text-align: right; 
  border: none;
  font-size: 1.5rem;
  letter-spacing: 1.2rem;
  margin-right: 20px;
}

.access-container input:focus{
  outline: none;
}