.btn.selector-btn div{
  display: flex;
  align-items: center;
}


.btn.selector-btn svg{

  margin-right: 1rem;
 }

.modal-body .operario-row:hover{
  cursor: pointer;
}


.operario-row.selected{
  background-color: #d1ecf1;
}



@media only screen and (max-width: 640px) {
 
 .btn.selector-btn{
  font-size: 0.8rem;
  padding-top: 2px ;
  padding-bottom: 2px ;
 }

 .modal{
  font-size: 0.8rem;
 }

 .modal .btn{
  font-size: 0.8rem;
 }

 .modal-title{
  font-size: 1.2rem;
 }
}