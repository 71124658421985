

*, *:before, *:after {
  box-sizing: border-box;
}
/* * { outline: 1px solid red; }  */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .calendario {
  margin-top: 15px;
} */
.form-control.licencia-select {
  width: 30%;
  font-size: inherit;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
#resumen-licencia {
  margin-bottom: 0;
}
.admin-select {
  max-width: 60%;
}
.password-input {
  margin-left: -1.5px;
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
#app-nav{
  /* style={{ width: "100vw", position: "fixed", top: 0, zIndex: 5000 }} */
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding-top:0;
  padding-bottom:0;
  
  background-color:white;
}

#app-nav .navbar-brand {
  display: flex;
}

#exit img{
  max-height: 60px;
}


.nav-item img {
max-height: 60px;

}

#logo{
  margin-right: 1rem;
}

#logo img{
  width: 75px;
  height: auto;
  max-height: max-content;
}


#avatar{
  margin-right: 2rem;
}


#avatar figure{
  margin-bottom: 0;
  width: 100%;
  height: auto;
}
 #avatar img{
  margin-bottom: 0;
  /* height: 54px; */
}
#avatar figcaption{
  color: black;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

#app-container{ 
  margin-top: 100px;
  width: 100vw;
  max-width: 1000px
}



@media only screen and (max-width: 640px) {

  /* APP NAV*/

  #app-nav *{
    font-size: 0.8rem;
  }
  #app-nav .navbar-collapse.show{
    padding-bottom: 5px;
  
  }
  
 #logo img{
    width:60px;
    height: auto;
  }
  #avatar, #avatar figure,  #avatar img{
    padding: 0;
  }
  #avatar img{
    max-height: 40px;
  }


  /* ROUTER *************************************************************************/
  
#app-container{ 
  margin-top: 80px;
}

  .router-links{
    overflow-anchor:none;
  }

  .router-links .menu {
    font-size: 0.8rem;
    padding: 0.2rem;
    max-height: 26px;
  }
  .btn-refresh{
    width: 32px;
    height: 30px;
  }

  .form-admin, #admin-select {
    font-size: 0.7rem;
  
  }

  #admin-select{
    height: 1.3rem;
    padding: 0 8px;
  }

 /* CALENDARIO  **********************************************************/
  .calendario{
    font-size: 0.8rem
  }
  
    .calendario .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
      min-height: 70vh;
    }
    
    .calendario .fc .fc-toolbar-title {
      font-size: 1rem;
    }
    .calendario .fc-button.fc-button-primary,
    button.fc {
      padding: 0.1rem;
    }
    .calendario .form-control.licencia-select {
      width: 50%;
    }
      
    .calendario .fc-scrollgrid-section-body{
      height: 75px;
    }
  
    .calendario a.dia-festivo{
      padding-left: 7px;
      padding-right: 7px;
    }
  /* PARTE MENSUAL **********************************************************/
  .parte-mensual{
    font-size: 0.7rem;
  }

    /*  HORAS *************************************************************/
    .horas-acum{
      font-size: 0.7rem;
    }
    .info{
      width: 100%;
    }
    .info div{
      min-width: 75px;
    }
    .horas-acum-total{
      width: 100%;
    }
    
  
}
