.calendario {
  margin-top: 15px;
}

.calendario .dia-festivo{
  background-color: #dc3545;
  margin-top: 2px;
  color: #f8f9fa;
  text-align: center;
  font-weight: bold;
  padding: 2px 10px 2px 10px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 20px;
}

.calendario-leyenda{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}


.calendario .info-horas{
  text-align: center;
  width: 80%;
  margin: auto;
}


#day-info-modal{
  
  margin: auto;
  margin-top: min(50%, 120px);
  max-width: min(100%, 800px);
  margin-bottom: auto;
}

#day-info-modal .modal-body{
  overflow:scroll;
}

@media only screen and (max-width: 640px) {

  #day-info-modal .modal-body{
    font-size:0.8rem;
  }
  #day-info-modal .modal-title{
    font-size:1.2rem;
  }
}

/* bg-danger text-light font-weight-bold text-center p-1 rounded-circle */