.app-container .admin-container{
  margin-top: 80px;
}


.app-container .buttons-container {
  background-color: rgba(40,167,69,0.8);
  padding: 5px;
  width: 80px;
  height: 40px;
  position: fixed;
  top: 180px;
  left: 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.app-container .buttons-container svg{
  width: 2rem;
  height: 2rem;
  fill: #fffa;
}

.app-container .buttons-container svg:hover{
  cursor: pointer;
  /* background-color: rgba(40,167,69,1); */
  fill: white;
}



.admin-data.table{
  width: 80%;
}


 .operario-or,
 .operario-id{
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}
.operario-data{
  width:100px;
  max-width: 100px;

}

.operario-or,
.operario-id{
  text-align: center;
}

.operario-or{
  font-weight: bold

}

.operario-data{
  text-align: right;
}


.operario-desc{
  width: 300px;
  min-width: 300px;
}



