.info {
  display: flex;
  justify-content:space-around;
  margin: 0 auto 1rem auto;
  /* padding: 10px 10px; */
  width: 70%;
}

.filtros-title{
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}


.info div{
  min-width: 110px;
  border-radius: 5px;
  text-align: center;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  
  
}

.info div.checked{
  color:black;
  font-weight: bold;
  box-shadow: inset rgba(0, 0, 0, 0.3) 0px -3px 5px;
  
}


.horas-acum-total{
  display: block;
  text-align: center;
  font-weight: bold;
  padding: 5px 10px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;

}
.td-number {
  text-align: right;
}

.td-date {
  text-align: center;
}

.td-number.total {
  font-weight: bold;
}




