.vacaciones-container{
  border: 1px solid rgb(94, 94, 94);
  border-radius: 10px;
  padding: 5px 15px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.vacaciones-container-title{
  background-color: #17a2b8;
  color: white!important;
  display: block;
  text-align: center;
  padding: 5px 20px;
  font-weight: bold;
}

.vacaciones-container-title.vacaciones-consumidas{
  background-color: grey;
 
}


.vacaciones-title{
  color: black;
  font-weight: bold;
  display: block;
  text-align: center;
}


@media only screen and (max-width: 640px) {
 
  .accordion {
    font-size: 0.7rem;
  }
}