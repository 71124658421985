

.router-links .menu {
  border: 0;
  border-radius: 0;
  background-color: #6c757d;
  margin: 0.1rem;
}
.router-links .menu.deprecated{
  background-color: #d39e00;
}
.router-links .menu.deprecated:hover{
  opacity: 0.7;
}

.form-admin{
  text-align: center;
}
.form-admin span{
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #ffc107;
  padding: 4px 15px;
  border-radius: 4px;

}

.form-select-container{
  display: flex;
  align-content: center;
  justify-content: center;

}

.form-select-container select{
  margin-left: 2rem;
  width: 80%;
}


.btn.refresh-btn{
  border-radius: 50%;
}

/* 
.btn-refresh{
  width: 40px;
  height: 38px;
  background-color: rgba(40,167,69,0.8);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
}

.btn-refresh svg{
  fill: #fffa;
  width: 80%;
  height: 80%;

}

.btn-refresh:hover svg{
  fill: white;

} */

.router-links{
  display: flex;
  justify-content: center;
  
}

.btn.refresh-btn {
  padding-left: .3rem;
  padding-right: .3rem;
 }

.user-tools{
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .btn.refresh-btn {
  
    font-size: .5rem;
  }
  .btn.refresh-btn svg{
    width:1rem;
    height: 1rem;
  }
 
 }
