#data-table{
  text-align: center;
}



#data-table tr:first-child th:nth-child(2), #data-table .normales{
  color: var(--primary);
}
#data-table tr:first-child th:nth-child(3),#data-table .festivas{
  color: var(--success);
}
#data-table .recuperar{
  color: var(--danger);
  font-weight: bold;
}

/* #data-table .data-row:nth-child(odd){
  background-color: var( --light)
} */